import axiosInstance from "../../../shared/utils/api";

export const fetchUserTasks = async (page, size) => {
    try {
        const response = await axiosInstance.get(`/user-task?page=${page}&size=${size}`)
        console.log('fetchUserTasks', response.data.data);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching user tasks:', error);
        return null;
    }
}
export const fetchValidateUserTaskById = async (taskId, token, code) => {
    const postData = {
      code: code
    };
    try {
        const response = await axiosInstance.post(`/user-task/${taskId}/check`, postData, {
            headers: {
                'Authorization': token,
            }
        })
        console.log('validateUserTaskById', response.data.data);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching user tasks:', error);
        return null;
    }
}
