import {fetchUserReferralsFirstLevel} from '../api/referralActions';

// Action Types
export const SET_REFERRALS = 'SET_REFERRALS';

// Action Creators
export const setReferrals = (referrals) => ({
    type: SET_REFERRALS,
    payload: referrals,
});

export const getUserReferrals = (page, size) => async (dispatch) => {
    try {
        const firstLevelResponse = await fetchUserReferralsFirstLevel(page, size);
        console.log("getUserReferrals firstLevelResponse: ", firstLevelResponse)

        if (firstLevelResponse) {
            const data = {
                'referrals-first-level': firstLevelResponse.content,
                first: firstLevelResponse.first,
                last: firstLevelResponse.last,
                empty: firstLevelResponse.empty,
                totalElements: firstLevelResponse.totalElements,
                totalPages: firstLevelResponse.totalPages,
                currentPage: firstLevelResponse.pageable.pageNumber
            }
            console.log("setReferrals data: ", data)
            dispatch(setReferrals(data));
        }
    } catch (error) {
        console.error('Failed to fetch referrals', error);
    }
};
