import React from 'react';
import {
    selectFirstLevelReferrals,
    selectReferralCurrentPageNumber,
    selectReferralsEmpty, selectReferralsTotalElements
} from '../model/referralSelectors';
import {useDispatch, useSelector} from "react-redux";
import "./style.css";
import ReferralItem from "./ReferralItem";
import {selectLast} from "../../Task/model/taskSelectors";
import {getUserReferrals} from "../model/referralActions";
import {selectUserToken} from "../../User/model/userSelectors";
import {translate} from "../../../shared/utils/translator";

const ReferralList = () => {
    const referrals = useSelector(selectFirstLevelReferrals);
    const last = useSelector(selectLast);
    const empty = useSelector(selectReferralsEmpty);
    const currentReferralPage = useSelector(selectReferralCurrentPageNumber);
    const userToken = useSelector(selectUserToken);
    const totalElements = useSelector(selectReferralsTotalElements);
    const dispatch = useDispatch();

    console.log(`last: ${last} empty: ${empty} currentReferralPage: ${currentReferralPage} totalElements: ${totalElements}`);
    const requestMoreReferrals = () => {
        if (!last) {
            dispatch(getUserReferrals(currentReferralPage + 1, 20, userToken));
        }
    };
    return (
        <>
            {empty ?
                <div className="referral-list-empty">
                    You currently have no invited frens.
                </div>
                :
                <>
                    <p className="frens-quantity">{translate("referral-list-title")}<sup>{totalElements}</sup></p>
                    {referrals.map((referral, index) => {
                        return (
                            <div className="referral-item" key={index}>
                                <ReferralItem referral={referral} />
                            </div>
                        );
                    })}
                </>

            }
            {
                !last && <button className="add-more-referrals-button" onClick={requestMoreReferrals}>See more your Frens</button>
            }
        </>
    );
};

export default ReferralList;
