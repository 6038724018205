import axiosInstance from "../../../shared/utils/api";

export const fetchCurrentUser = async () => {
    try {
        const response = await axiosInstance.get(`/users/current`);
        console.log('fetchCurrentUser:', response.data.data);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching current user:', error);
        return null;
    }
};

export const fetchAuthUser = async (userData) => {
    try {
        const postData = {
            'tg-data': userData,
        };

        const response = await axiosInstance.post(`/public/authentication`, postData, { skipAuth: true });

        if (response.status === 204) {
            console.log('No content returned.');
            return null;
        }

        const authHeader = response.headers['authorization'];
        console.log('Authorization Header:', authHeader);
        return authHeader;
    } catch (error) {
        console.error('Error fetchAuthUser:', error);
        return null;
    }
};
