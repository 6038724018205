import {
    authUser,
    setCurrentUser,
    setUserToken
} from '../../entities/User/model/userActions';
import { getUserReferrals } from '../../entities/Referral/model/referralActions';
import { getUserTasks } from '../../entities/Task/model/taskActions';
import { setLanguage } from '../../entities/Language/model/languageActions';
import useTelegram from "../../shared/hooks/useTelegram";

export const initializeApp = (userData) => async (dispatch) => {
    const {onClose, initDataUnsafe} = useTelegram();

    console.log("initDataUnsafe", initDataUnsafe);
    try {
        const userToken = await dispatch(authUser(userData));
        if (userToken) {
            await dispatch(setUserToken(userToken));
            await dispatch(setLanguage(initDataUnsafe.user.language_code));
            console.log("Language is set", initDataUnsafe.user.language_code);
            await dispatch(setCurrentUser());
            await dispatch(getUserTasks(0, 20));
            await dispatch(getUserReferrals(0, 20));
        } else {
            console.error('Authentication failed, stopping further initialization.');
            onClose();
        }
    } catch (error) {
        console.error('Failed to initialize app:', error);
    }
};
