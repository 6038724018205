import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectTasks, selectTasksData} from '../model/taskSelectors';
import TaskItem from "./TaskItem";
import "./style.css";
import {addMoreUserTasks} from "../model/taskActions";
import {selectUserToken} from "../../User/model/userSelectors";

const TaskList = () => {
    const dispatch = useDispatch();
    const token = useSelector(selectUserToken);
    const tasksData = useSelector(selectTasksData);
    const tasks = useSelector(selectTasks);

    console.log("TasksData", tasksData);

    const handleButtonLoadingNewData = () => {
        console.log("handleButtonLoadingNewData is clicked");
        const nextPage = tasksData.number + 1;
        console.log(`nextPage: ${nextPage} totalPages: ${tasksData.totalPages}`);
        if (nextPage < tasksData.totalPages) {
            dispatch(addMoreUserTasks(nextPage, 20, token));
        }
    };

    return (
        <>
            {tasks && tasks.map((task, index) => (
                <div className="task-item" key={index}>
                    <TaskItem task={task}/>
                </div>
            ))}
            {!tasksData.last &&
                <button className="button-new-task-items" onClick={() => handleButtonLoadingNewData()}>
                    Load new data
                </button>
            }
            {/*<h2>Active Tasks</h2>*/}
            {/*<ul>*/}
            {/*    {tasks.filter(task => task.status === null && task.is_active).map(task => (*/}
            {/*        <li key={task.id}>*/}
            {/*            {task.title}*/}
            {/*            <button onClick={() => handleClaimTask(task.id)}>Claim</button>*/}
            {/*        </li>*/}
            {/*    ))}*/}
            {/*</ul>*/}

            {/*<h2>Claimed Tasks</h2>*/}
            {/*<ul>*/}
            {/*    {tasks.filter(task => task.status === 'Claimed').map(task => (*/}
            {/*        <li key={task.id}>*/}
            {/*            {task.title}*/}
            {/*            <button onClick={() => handleCompleteTask(task.id)}>Complete</button>*/}
            {/*        </li>*/}
            {/*    ))}*/}
            {/*</ul>*/}

            {/*<h2>Completed Tasks</h2>*/}
            {/*<ul>*/}
            {/*    {tasks.filter(task => task.status === 'Completed').map(task => (*/}
            {/*        <li key={task.id}>*/}
            {/*            {task.title}*/}
            {/*        </li>*/}
            {/*    ))}*/}
            {/*</ul>*/}
        </>
    );
};

export default TaskList;
