export const selectUser = (state) => state.user;
export const selectTotalPoints  = (state) => state.user.total_points;
export const selectUserName = (state) => state.user.nickname;
export const selectUserId = (state) => state.user.id;

export const selectUserInvite = (state) => state.user.invite;

export const selectUserConsecutiveDays = (state) => state.user['consecutive-days'];

// export const selectUserFirstName = (state) => state.user.first_name;
// export const selectUserLastName = (state) => state.user.last_name;

// export const selectUserLanguageCode = (state) => state.user.language_code;
// export const selectUserIsPremium = (state) => state.user.is_premium;
// export const selectUserPhotoUrl = (state) => state.user.photo_url;
// export const selectUserIsAuthenticated = (state) => state.user.isAuthenticated;
// export const selectDailyTaskClaimed = (state) => state.user.dailyTaskClaimed;
// export const selectDailyTaskError = (state) => state.user.dailyTaskError;
export const selectUserToken = (state) => state.user.userToken;

