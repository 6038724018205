import React, { useEffect, useState } from "react";
import "./style.css";
import {translate} from "../../../shared/utils/translator";

const NewsItem = ({ news }) => {
    const { content, status, awards, expiredDate } = news;
    // TEST MODE
    const [userStatus, setUserStatus] = useState("START");
    // TEST MODE
    const [timeRemaining, setTimeRemaining] = useState("99:99:99");
    const [showFullText, setShowFullText] = useState(false);
    const [isLoading, setIsLoading] = useState(userStatus === "PENDING");

    const createdAt = content.split("CREATED_AT:")[1].split(" ")[0];

    const wordLimit = 40; // Approximately 6 lines worth of text
    const startingCharacterIndex = 22;

    const truncatedContent = content.slice(startingCharacterIndex);
    const words = truncatedContent.split(" ");
    const isContentTruncated = words.length > wordLimit;
    // const words = content.split(" ");
    // const isContentTruncated = words.length > wordLimit;
    // const startingCharacterIndex = 22;

    useEffect(() => {
        if (expiredDate) {
            const interval = setInterval(() => {
                const now = new Date().getTime();
                const expiryTime = new Date(expiredDate).getTime();
                const distance = expiryTime - now;

                if (distance <= 0) {
                    setTimeRemaining("Expired");
                    clearInterval(interval);
                } else {
                    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    setTimeRemaining(`${hours}:${minutes}:${seconds}`);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [expiredDate]);

    const toggleText = () => setShowFullText(!showFullText);

    const handleButtonClick = () => {
        if (userStatus === 'START') {
            setUserStatus('PENDING');
        }
        if (userStatus === 'PENDING') {
            setUserStatus('DONE');
        }
    };
    return (
        <>
            <div className="news-header">{createdAt}</div>
            {news.image &&
                <div className="image-container">
                    <img src={news.image} alt=""/>
                </div>
            }
            {news.video &&
                <div className="video-container">
                    <iframe src={news.video}></iframe>
                </div>
            }
            <div className={`news-content ${showFullText ? "expanded" : ""}`}>
                {/*{showFullText ? content.slice(startingCharacterIndex) : words.slice(0, wordLimit).join(" ") + (isContentTruncated ? "..." : "")}*/}
                {showFullText ? content.slice(startingCharacterIndex)  : words.slice(0, wordLimit).join(" ") + (isContentTruncated ? "..." : "")}
                {!showFullText && isContentTruncated && (
                    <span className="news-continue" onClick={toggleText}>continue</span>
                )}
            </div>
            <div className={`news-footer ${userStatus !== "START" ? 'border-white' : ''}`} onClick={handleButtonClick}>
                <div className={`overlay ${userStatus !== "START" ? 'visible' : ''}`}></div>
                {userStatus === "START" && (
                    <>
                        <div className="button-status-section">
                            {translate("button-status-section-news-item")}
                        </div>
                        <div className="button-main-section">
                        + {awards} CXP
                        </div>
                        <div className="button-timer-section">
                            {expiredDate} <span>{timeRemaining}</span>
                        </div>
                    </>
                )}
                {userStatus === "PENDING" && (
                    <>
                        <div className="button-status-section">
                        </div>
                        <div className="button-main-section">
                            <div className="news-loading-spinner">
                                <div className="spinner"></div>
                            </div>
                        </div>
                        <div className="button-timer-section">
                        </div>
                    </>
                )}
                {userStatus === "DONE" && (
                    <>
                        <div className="button-status-section">
                        </div>
                        <div className="button-main-section">
                            <div className="news-completed-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="12" fill="white" />
                                    <path d="M6 12L10 16L18 8" stroke="#6E8BF1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className="button-timer-section">
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default NewsItem;
