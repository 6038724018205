import axios from 'axios';
import { getEnvironmentConfig } from './environment';
import store from '../../app/store/index';
import { selectUserToken } from '../../entities/User/model/userSelectors';

const apiUrl = getEnvironmentConfig().apiUrl;

const axiosInstance = axios.create({
    baseURL: apiUrl,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = selectUserToken(store.getState());
        if (!config.skipAuth && token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export default axiosInstance;
