export default function useTelegram() {
    const tg = window.Telegram.WebApp;

    // Basic data
    const initData = tg.initData;
    const initDataUnsafe = tg.initDataUnsafe;
    const version = tg.version;
    const platform = tg.platform;
    const colorScheme = tg.colorScheme;
    const themeParams = tg.themeParams;
    const isExpanded = tg.isExpanded;
    const viewportHeight = tg.viewportHeight;
    const viewportStableHeight = tg.viewportStableHeight;
    const headerColor = tg.headerColor;
    const backgroundColor = tg.backgroundColor;
    const isClosingConfirmationEnabled = tg.isClosingConfirmationEnabled;
    const isVerticalSwipesEnabled = tg.isVerticalSwipesEnabled;

    // Additional objects and methods
    const backButton = tg.BackButton;
    const mainButton = tg.MainButton;
    const settingsButton = tg.SettingsButton;
    const hapticFeedback = tg.HapticFeedback;
    const cloudStorage = tg.CloudStorage;
    const biometricManager = tg.BiometricManager;

    // Function implementations
    const onClose = () => tg.close();
    const onExpand = () => tg.expand();
    const onReady = () => tg.ready();
    const onEnableClosingConfirmation = () => tg.enableClosingConfirmation();
    const onDisableClosingConfirmation = () => tg.disableClosingConfirmation();
    const onEnableVerticalSwipes = () => tg.enableVerticalSwipes();
    const onDisableVerticalSwipes = () => tg.disableVerticalSwipes();
    const onSetHeaderColor = (color) => tg.setHeaderColor(color);
    const onSetBackgroundColor = (color) => tg.setBackgroundColor(color);
    const onSendData = (data) => tg.sendData(data);
    const onSwitchInlineQuery = (query, chooseChatTypes) => tg.switchInlineQuery(query, chooseChatTypes);
    const onOpenLink = (url, options) => tg.openLink(url, options);
    const onOpenTelegramLink = (url) => tg.openTelegramLink(url);
    const onOpenInvoice = (url, callback) => tg.openInvoice(url, callback);
    const onShowPopup = (params, callback) => tg.showPopup(params, callback);
    const onShowAlert = (message, callback) => tg.showAlert(message, callback);
    const onShowConfirm = (message, callback) => tg.showConfirm(message, callback);
    const onShowScanQrPopup = (params, callback) => tg.showScanQrPopup(params, callback);
    const onCloseScanQrPopup = () => tg.closeScanQrPopup();
    const onReadTextFromClipboard = (callback) => tg.readTextFromClipboard(callback);
    const onRequestWriteAccess = (callback) => tg.requestWriteAccess(callback);
    const onRequestContact = (callback) => tg.requestContact(callback);
    const onIsVersionAtLeast = (version) => tg.isVersionAtLeast(version);
    const onEvent = (eventType, eventHandler) => tg.onEvent(eventType, eventHandler);
    const offEvent = (eventType, eventHandler) => tg.offEvent(eventType, eventHandler);
    const enableVerticalSwipes = () => tg.enableVerticalSwipes();
    const disableVerticalSwipes = () => tg.disableVerticalSwipes();


    return {
        tg,
        initData,
        initDataUnsafe,
        version,
        platform,
        colorScheme,
        themeParams,
        isExpanded,
        viewportHeight,
        viewportStableHeight,
        headerColor,
        backgroundColor,
        isClosingConfirmationEnabled,
        isVerticalSwipesEnabled,
        backButton,
        mainButton,
        settingsButton,
        hapticFeedback,
        cloudStorage,
        biometricManager,
        onClose,
        onExpand,
        onReady,
        onEnableClosingConfirmation,
        onDisableClosingConfirmation,
        onEnableVerticalSwipes,
        onDisableVerticalSwipes,
        onSetHeaderColor,
        onSetBackgroundColor,
        onSendData,
        onSwitchInlineQuery,
        onOpenLink,
        onOpenTelegramLink,
        onOpenInvoice,
        onShowPopup,
        onShowAlert,
        onShowConfirm,
        onShowScanQrPopup,
        onCloseScanQrPopup,
        onReadTextFromClipboard,
        onRequestWriteAccess,
        onRequestContact,
        onIsVersionAtLeast,
        onEvent,
        offEvent,
        enableVerticalSwipes,
        disableVerticalSwipes,
    }
}
