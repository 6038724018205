import TelegramIcon from "../../../shared/assets/images/TelegramIcon.svg";
import {useEffect, useState} from "react";
import "./style.css";
import {setTaskStatus, validateUserTaskById} from "../model/taskActions";
import useTelegram from "../../../shared/hooks/useTelegram";
import {useDispatch, useSelector} from "react-redux";
import {selectUserToken} from "../../User/model/userSelectors";
const TaskItem = ({ task }) => {
    const dispatch = useDispatch();
    const token = useSelector(selectUserToken);
    const {onOpenLink} = useTelegram();
    const { id, title, awards, status, content, expiredDate } = task;
    const [timeRemaining, setTimeRemaining] = useState("");

    useEffect(() => {
        if (expiredDate) {
            // Calculate time remaining
            const interval = setInterval(() => {
                const now = new Date().getTime();
                const expiryTime = new Date(expiredDate).getTime();
                const distance = expiryTime - now;

                if (distance <= 0) {
                    setTimeRemaining("Expired");
                    clearInterval(interval);
                } else {
                    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    setTimeRemaining(`${hours}:${minutes}:${seconds}`);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [expiredDate]);

    const handleTaskClick = () => {
        if (status === "START") {
            // Redirect user to task content
            // Use useTelegram openLink
            onOpenLink(content);

            dispatch(setTaskStatus(id, "PENDING"));

            // Start polling to validate task status
            const interval = setInterval(async () => {
                const result = await dispatch(validateUserTaskById(id, token));
                if (result) {
                    clearInterval(interval);
                    dispatch(setTaskStatus(id, "DONE"));
                }
            }, 3000); // Poll every 3 seconds
        }
    };

    return (
        <>
            <div className="full-task-button" onClick={handleTaskClick}></div>
            <div className="task-icon">
                {
                    title === 'Subscribe on Telegram' && <img src={TelegramIcon} alt="Telegram" />
                }
            </div>
            <div className="task-details">
                <h3 className="task-title">{title}</h3>
                <div className="task-meta">
                    <p className="task-awards">+ {awards} XCP</p>
                    {expiredDate && <span className="task-expiry">{timeRemaining}</span>}
                </div>
            </div>
            <div className="task-item-button">
                {status === "START" && (
                    <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="task-arrow">
                        <path d="M1 19L10 10L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                )}
                {status === "PENDING" && (
                        <div className="loading-spinner">
                            <div className="spinner"></div>
                        </div>
                    )
                }
                {status === "DONE" && (
                    <div className="task-completed-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="white" />
                            <path d="M6 12L10 16L18 8" stroke="#6E8BF1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                )}
            </div>
        </>
    );
};

export default TaskItem;
