import axiosInstance from "../../../shared/utils/api";

export const fetchUserReferralsFirstLevel = async (page, size) => {
    try {
        const response = await axiosInstance.get(`/users/referrals/first-level?page=${page}&size=${size}`)
        console.log('fetchUserReferralsFirstLevel', response.data.data);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching user referrals first lvl:', error);
        return null;
    }
};
