export const selectTasksData = (state) => state.task.tasks;

export const selectDailyCheckCode = (state) => state.task.tasks.daily_check_codes[0];
export const selectDailyCheckCodes = (state) => state.task.tasks.daily_check_codes;
export const selectTasks = (state) => state.task.tasks.tasks;
export const selectFirst = (state) => state.task.tasks.first;
export const selectLast = (state) => state.task.tasks.last;
export const selectTotalElements = (state) => state.task.tasks.totalElements;
export const selectTotalPages = (state) => state.task.tasks.totalPages;
export const selectDailyCodeTaskStatus = (state) => state.task.tasks.daily_check_codes[0].status
