import {useState} from "react";

const DigitScroller = ({ digit, index, updateDigit }) => {
    const [isScrolling, setIsScrolling] = useState(false);
    const [startY, setStartY] = useState(0);


    const handleWheel = (event) => {
        if (isScrolling) return;

        setIsScrolling(true);

        let newDigit = digit;
        if (event.deltaY > 0) {
            newDigit = (digit + 9) % 10;
        } else if (event.deltaY < 0) {
            newDigit = (digit + 1) % 10;
        }

        updateDigit(index, newDigit);

        setTimeout(() => {
            setIsScrolling(false);
        }, 200); // Adjust this value for smoother or faster scrolling
    };

    const handleTouchStart = (event) => {
        const touch = event.touches[0];
        setStartY(touch.clientY);
    };

    const handleTouchMove = (event) => {
        if (isScrolling) return;

        const touch = event.touches[0];
        const deltaY = startY - touch.clientY;

        if (Math.abs(deltaY) > 10) { // Adjust this threshold as needed
            setIsScrolling(true);

            let newDigit = digit;
            if (deltaY > 0) {
                newDigit = (digit + 9) % 10;
            } else if (deltaY < 0) {
                newDigit = (digit + 1) % 10;
            }

            updateDigit(index, newDigit);

            setTimeout(() => {
                setIsScrolling(false);
            }, 200); // Adjust this value for smoother or faster scrolling
        }
    };

    // const previousDigit = (digit + 1) % 10;
    // const nextDigit = (digit + 9) % 10;
    return (
        <div
            className="digit-container"
              onWheel={handleWheel}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}>
            <div className="digit-scroll">
                {/*<span className="digit previous">{previousDigit}</span>*/}
                <span className="digit current">{digit}</span>
                {/*<span className="digit next">{nextDigit}</span>*/}
            </div>
        </div>
    );
};

export default DigitScroller;
