import React from "react";
import NewsItem from "./NewsItem";

const NewsList = () => {
    const empty = false
    const newsArray = [
        {
            "id": 154,
            "title": "Binance News",
            "status": "START",
            "type": "TELEGRAM",
            "content": "CREATED_AT:2024-08-16 Contrary to popular belief, Lorem Ipsum is not simply random text. https://twitter.com/ It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. 45 BC, making it over 2000 years old. Contrary to popular belief, Lorem Ipsum is not simply random text. https://twitter.com/ It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. 45 BC, making it over 2000 years old.",
            "awards": 10000,
            "expiredDate": null,
            "image": "https://www.lebigdata.fr/wp-content/uploads/2020/12/bitcoin-tout-savoir-guide-ultime.jpg",
            "video": null
        },
        {
            "id": 155,
            "title": "Binance News",
            "status": "START",
            "type": "TELEGRAM",
            "content": "CREATED_AT:2024-08-16 Contrary to popular belief, Lorem Ipsum is not simply random text. https://twitter.com/ It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. 45 BC, making it over 2000 years old.",
            "awards": 10000,
            "expiredDate": null,
            "image": null,
            "video": "https://www.youtube.com/embed/xOMMV_qXcQ8?controls=0"
        },
        {
            "id": 156,
            "title": "Binance News",
            "status": "START",
            "type": "TELEGRAM",
            "content": "CREATED_AT:2024-08-16 Contrary to popular belief, Lorem Ipsum is not simply random text. https://twitter.com/ It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. 45 BC, making it over 2000 years old.",
            "awards": 10000,
            "expiredDate": null,
            "image": null,
            "video": null
        },
        {
            "id": 157,
            "title": "Binance News",
            "status": "START",
            "type": "TELEGRAM",
            "content": "CREATED_AT:2024-08-16 Contrary to popular belief, Lorem Ipsum is not simply random text. https://twitter.com/ It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. 45 BC, making it over 2000 years old.",
            "awards": 10000,
            "expiredDate": null,
            "image": null,
            "video": null
        },
        {
            "id": 158,
            "title": "Binance News",
            "status": "START",
            "type": "TELEGRAM",
            "content": "CREATED_AT:2024-08-16 Contrary to popular belief, Lorem Ipsum is not simply random text. https://twitter.com/ It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. 45 BC, making it over 2000 years old.",
            "awards": 10000,
            "expiredDate": null,
            "image": null,
            "video": null
        },
        {
            "id": 159,
            "title": "Binance News",
            "status": "START",
            "type": "TELEGRAM",
            "content": "CREATED_AT:2024-08-16 Contrary to popular belief, Lorem Ipsum is not simply random text. https://twitter.com/ It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. 45 BC, making it over 2000 years old.",
            "awards": 10000,
            "expiredDate": null,
            "image": null,
            "video": null
        },
        {
            "id": 160,
            "title": "Binance News",
            "status": "START",
            "type": "TELEGRAM",
            "content": "CREATED_AT:2024-08-16 Contrary to popular belief, Lorem Ipsum is not simply random text. https://twitter.com/ It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. 45 BC, making it over 2000 years old.",
            "awards": 10000,
            "expiredDate": null,
            "image": null,
            "video": null
        },
        {
            "id": 161,
            "title": "Binance News",
            "status": "START",
            "type": "TELEGRAM",
            "content": "CREATED_AT:2024-08-16 Contrary to popular belief, Lorem Ipsum is not simply random text. https://twitter.com/ It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. 45 BC, making it over 2000 years old.",
            "awards": 10000,
            "expiredDate": null,
            "image": null,
            "video": null
        },
        {
            "id": 162,
            "title": "Binance News",
            "status": "START",
            "type": "TELEGRAM",
            "content": "CREATED_AT:2024-08-16 Contrary to popular belief, Lorem Ipsum is not simply random text. https://twitter.com/ It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. 45 BC, making it over 2000 years old.",
            "awards": 10000,
            "expiredDate": null,
            "image": null,
            "video": null
        },
        {
            "id": 163,
            "title": "Binance News",
            "status": "START",
            "type": "TELEGRAM",
            "content": "CREATED_AT:2024-08-16 Contrary to popular belief, Lorem Ipsum is not simply random text. https://twitter.com/ It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. 45 BC, making it over 2000 years old. Contrary to popular belief, Lorem Ipsum is not simply random text. https://twitter.com/ It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. 45 BC, making it over 2000 years old.",
            "awards": 10000,
            "expiredDate": null,
            "image": null,
            "video": null
        }
    ];

    return (
      <div>
          {empty ?
              <div className="referral-list-empty">
                  We currently don't have no news.
              </div>
              :
              newsArray.map((item, index) => (
                  <div className="news-item" key={index}>
                      <NewsItem news={item} />
                  </div>
              ))
          }
      </div>
    );
}
export default NewsList;
