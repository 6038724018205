import React from 'react';
import "./LoadingPage.css";
import LoadingImage from "../assets/images/Logo Small.jpg";

const LoadingPage = () => {
    return (
        <div className="loading-container">
            <img src={LoadingImage} alt="Loading..." className="loading-image" />
            <h1>Collective.Money</h1>
            <h3>Future of crypto</h3>
        </div>
    );
};

export default LoadingPage;
