import BottomNavigationBar from "../../shared/components/BottomNavigationBar";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from "../../pages/HomePage";
import FrensPage from "../../pages/FrensPage";
import QuestPage from "../../pages/QuestPage";
import TaskPage from "../../pages/TasksPage";
import NewsPage from "../../pages/NewsPage";
import Header from "../../shared/components/Header";
import React, {useEffect, useState} from "react";
const AppRoutes = () => {
    const [currentPage, setCurrentPage] = useState('');

    const goToPage = (page) => setCurrentPage(page);

    const pageComponents = {
        '': <HomePage />,
        'Tasks': <TaskPage />,
        'Quest': <QuestPage />,
        'Frens': <FrensPage />,
        'News': <NewsPage />
    };

    const CurrentPageComponent = pageComponents[currentPage] || null;

    return (
        <>
            <Header goToPage={goToPage} />
            {CurrentPageComponent}
            <BottomNavigationBar goToPage={goToPage} />
        </>
    )
}

export default AppRoutes;