import { SET_REFERRALS } from './referralActions';

const initialState = {
    referrals: null
};

const referralReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REFERRALS:
            return {
                ...state,
                referrals: action.payload,
            };
        default:
            return state;
    }
};

export default referralReducer;
