import "./BottomNavigationBar/BottomNavigationBar.css";
import React, {useState} from "react";
import {translate} from "../utils/translator";
import {FrensIcon, HomeIcon, NewsIcon, QuestIcon, TasksIcon} from "./BottomNavigationBar/HomeIcon";

export default function BottomNavigationBar({goToPage}) {
    const [activeButton, setActiveButton] = useState('');

    const buttonsData = [
        {
            title: translate("bottom_navigation_button_home"),
            icon: <HomeIcon active={activeButton === ''}
                // className={`nav-icon ${activeButton === 'Home' ? 'active' : ''}`}
            />,
            // notification: notifications.home,
            path: ''
        },
        {
            title: translate("bottom_navigation_button_tasks"),
            icon: <TasksIcon active={activeButton === 'Tasks'}/>,
            // notification: notifications.tasks,
            path: 'Tasks'
        },
        {
            title: translate("bottom_navigation_button_quest"),
            icon: <QuestIcon active={activeButton === 'Quest'}/>,
            // notification: notifications.quest,
            path: 'Quest'
        },
        {
            title: translate("bottom_navigation_button_frens"),
            icon: <FrensIcon active={activeButton === 'Frens'}/>,
            // notification: notifications.frens,
            path: 'Frens'
        },
        {
            title: translate("bottom_navigation_button_news"),
            icon: <NewsIcon active={activeButton === 'News'}/>,
            // notification: notifications.news,
            path: 'News'
        },
    ];

    const handleButtonClick = (path) => {
        setActiveButton(path);
        goToPage(path)
        // navigate(path);
    };

    return (
        <footer>
            {buttonsData.map((item, index) => (
                <div className={`nav-item ${activeButton === item.path ? 'active' : ''}`}
                     onClick={() => handleButtonClick(item.path)} key={index}>
                    <div className="icon-container">
                        {item.icon}
                    </div>
                    <span className="nav-text">{item.title}</span>
                    {/*{*/}
                    {/*    item.notification.length > 0 &&*/}
                    {/*    <span className="notification-badge">{item.notification.length}</span>*/}
                    {/*}*/}
                </div>
            ))}
        </footer>
    );
}
